.side-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  z-index: 999;
  animation: glowInRight 2s ease-in-out infinite;
  right: 0; /* Stick to the right side */
}

@keyframes glowInRight {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 10px 5px rgba(0, 123, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
}

.side-button .btn {
  width: auto;
  height: 40px;
  font-size: 14px;
  white-space: nowrap;
  background: linear-gradient(to right, #00A8E8, #007BFF);
  border: none;
  color: #fff;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.side-button .btn:hover {
  box-shadow: 0 0 10px 5px rgba(0, 123, 255, 0.7);
  transform: scale(1.1);
}

/* Adjusted value for all screen sizes */
.side-button {
  right: -65px;
}

/* Desktop view */
@media (min-width: 768px) {
  .side-button {
    right: -65px;
    transform: translateY(-50%) rotate(-90deg);
  }
}
