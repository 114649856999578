.seat-matrix {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.filters {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filters label {
    color: #555;
    font-weight: bold;
}

.filters select {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    color: #555;
    font-size: 14px;
}

table {
    width: 90%;
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
}

th {
    background-color: rgb(109, 169, 228);
    color: #555;
    font-weight: bold;
}

.highlight {
    background-color:  rgb(246, 186, 111);
    position: relative;
}

.highlight::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    background-color: rgb(109, 169, 228);
    border: 2px solid white;
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
}

@media screen and (max-width: 500px) {
    .seat-matrix {
        width: 100%;
    }
}

.no-results-message {
    background-color: #f8f8f8;
    color: #555;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
    text-align: center;
}
.clear-button {
    background-color: #f8f8f8;
    color: #555;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .clear-button:hover {
    background-color: #e2e2e2;
  }
  