.lppopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.lppopup-content {
  background-color: #fff;
  max-width: 500px;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.lpheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  margin: 0;
}

.close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #333;
  padding: 0;
}

.close:hover {
  color: #f00;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.footer {
  margin-top: 20px;
}

.btn-secondary {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #555;
}

@media (max-width: 600px) {
  .lppopup-content {
    max-width: 90%;
  }
}
