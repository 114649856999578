.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  flex: 1;
}

.logo img {
  height: 40px;
}

.logout-button {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #0056b3;
}

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 250px;
  background-color: #f0f0f0;
  padding: 20px;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.card img {
  width: 200px;
  height: 200px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
}

/* .card img {
  max-width: 100%;
  height: auto;
} */

.card-teacher{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  width: 400px;
}

.notice-editor {
  font-family: Arial, sans-serif;
  width: 75%;
  margin: 0 auto;
}

.notice-editor h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.add-notice {
  margin-bottom: 30px;
}

.add-notice h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.add-notice input[type="text"],
.add-notice textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-container {
  margin-bottom: 10px;
}

.dropdown-container label {
  margin-right: 10px;
}

.dropdown-container select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-notice button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-notice button:hover {
  background-color: #45a049;
}

.category {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  position: relative;
}

.heady {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;
  background-color: #f1f1f1;
  /* Add desired background color */
  border: 5px solid black;
  /* Add black border with a width of 5px */
  border-radius: 10px;
  /* Add desired border radius */
  padding: 15px;
}


.expand-button {
  background-color: #f9f9f9;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 20px;
}

.blop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  min-width: 50%;
  min-height: 26%;
}

.blop h4 {
  margin-top: 0;
}

.blop input,
.blop textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.blop button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.blop button.cancel-button {
  background-color: #f44336;
}

.blop button:focus {
  outline: none;
}

.blop button:not(:last-child) {
  margin-right: 10px;
}

.notice-cards {
  margin-top: 10px;
}

.bard {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.bard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bard-header h3 {
  font-size: 18px;
  margin: 0;
}

.bard-header p {
  font-size: 14px;
  margin: 0;
}

.edit-button {
  padding: 4px 8px;
  background-color: #f1f1f1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #e1e1e1;
}

.delete-button {
  padding: 4px 8px;
  background-color: #f1f1f1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #e1e1e1;
}

.editor-container {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
  box-sizing: border-box;
}

.editor {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
  box-sizing: border-box;
}

.button-container {
  margin-top: 10px;
}

.button-container button {
  margin-right: 10px;
}