.scrolling-text-container {
    white-space: nowrap;
    overflow: hidden;
    background: rgb(255, 255, 255);
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .scrolling-text-container span {
    display: inline-block;
    animation: scroll linear infinite;
    animation-duration: 20s; /* Adjust the duration as needed */
    animation-timing-function: steps(6000, end); /* Adjust the number of steps for smoother animation */
  }
  .no-padding {
    padding-left: 0;
    padding-right: 0;
    margin-right: -30px;
    margin-left: -30px;
  }
  