.notice-board {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 450px;
  /* Set minimum height */
  overflow: hidden;
  /* Hide overflow to prevent scrollbars */
  padding: 30px;
}

.notice-box {
  width: 30%;
  margin-bottom: 20px;
  padding: 0px;
  /* border: 1px solid #ccc;
  border-radius: 4px; */
  position: relative;
}

.notice-box h2 {
  margin: 0;
  padding: 0;
  position: sticky;
  /* Add sticky positioning */
  top: 0;
  /* Stick to the top */
  /* background-color: rgb(246, 186, 111); Adjust background color as needed */
  width: 100%;
  /* Set h2 to full width */
  text-decoration: underline overline dotted;
  text-align: start;
  padding-left: 10px;
}

.scrollable-list-container {
  height: 250px;
  /* Set the desired maximum height */
  overflow-y: auto;
  margin-top: 20px;
  /* Add top margin */
  padding-top: 20px;
  /* Add top padding */
}


.scrollable-list {
  height: 250px;
  animation: noticescrollList 10s linear infinite;
  /* Enable auto-scrolling */
  margin-top: 20px;
  /* Add top margin */
  padding-top: 20px;
  /* Add top padding */
}

@keyframes noticescrollList {
  0% {
    transform: translateY(0);
  }

  /* Adjust this value to set the top scrolling limit */
  100% {
    transform: translateY(calc(-100%));
  }

  /* Adjust based on item height and margin */
}

.list-item {
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 5px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
}

.list-item:hover {
  background-color: #e0e0e0;
}

.title {
  flex-grow: 1;
}

.modaltest {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;
}

.modaltest-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 100vw;
  max-height: 100vw;
  overflow-y: auto;
}

.modaltest-content h3 {
  margin-top: 0;
}

.modaltest-content button {
  margin-top: 10px;
}

.modaltest-content button.close-button {
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.modaltest-content button.close-button:hover {
  background-color: #e0e0e0;
}

.example-date {
  background-color: rgb(109, 169, 228);
  float: left;
  position: relative;
  padding: 25px 4px 0;
  color: #fff;
  width: 50px;
  padding-right: 20px;
}

.example-date .day {
  font-size: 16px;
  position: absolute;
  left: 8px;
  top: 8px;
}

.example-date .month {
  text-transform: uppercase;
  font-size: 12px;
}

.example-date .year {
  position: absolute;
  right: -11px;
  top: 15px;
  transform: rotate(90deg);
  padding-right: 5px;
}

.title {
  flex-grow: 1;
}

/* Mobile View */
@media (max-width: 767px) {
  .notice-board {
    flex-direction: column;
  }

  .notice-box {
    width: 100%;
  }
}