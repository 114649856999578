.mycont {

  background-color: rgb(112, 163, 234);
  text-align: left;

}

.link {
  text-decoration: none;

  color: rgb(240, 235, 235);
}

a:hover {
  color: rgb(6, 75, 179);
}

.colcolor {
  background-color: rgb(98, 154, 231);
}

a:link {
  text-decoration: none;
}

.custom-table tbody td {
  text-align: left;
  -webkit-text-fill-color: rgb(14, 13, 13);
  border-color: rgb(85, 133, 230);
  background-color: #ECF2FF;
  opacity: 1px;

}

p {
  margin: 10px 0;
  padding: 5px;
  margin-bottom: 2em;
}

.about-content {
  padding: 0 20%;
  text-align: justify;
  overflow: hidden;
  position: relative;
  max-height: 100px;
  /* Adjust the initial height as desired */
  min-height: 250px;
  height: auto;
}

.about-content.expanded {
  max-height: none;
  /* Expand the height to show full content */
}

.heading-text {
  padding: 2% 20%;
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
}

.about-us {
  background-color: #f4f4f4;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  background-repeat: repeat;
  min-height: 200px;
  height: auto;
  display: flex;
  align-items: center;
}

.read-more-text {
  color: rgb(98, 154, 231);
  cursor: pointer;
}

.carousel-container {
  background-color: transparent;
}

.image-wrapper {
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.container-sm {
  background-color: #f5f5f500;
  /* Fallback color */
  /* background-image:
    linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%),
    linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%);
  background-size: 10px 10px; */
  /* border: 2px solid rgb(109, 169, 228); */
  margin-right: 20px;
  border-radius: 10px;
}

.sectionbg {
  height: 200px;
  width: 100%;
  background-color: rgb(109, 169, 228);
  background-image:
    linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.1) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.1) 75%);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.counter-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  background-color: transparent;
}

.counter-item {
  text-align: center;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}

.counter-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid rgb(0, 0, 0);
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0) 30%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 70%);
  border-image-slice: 1;
  animation: wave-animation 2s infinite linear;
}

@keyframes wave-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}


.counter-number {
  font-size: 50px;
  font-weight: bold;
  color: black;
}

.counter-label {
  font-size: 25px;
  color: black;
  margin-top: 10px;
}


body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transition: opacity 1.5s;
  }

  100% {
    opacity: 1;
  }
}

.wrapper {
  width: 100%;
  max-width: 100%;
  min-height: 500px;
  max-height: fit-content;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 40px 25px;
  margin: 20px auto;
  /* border-radius: 10px; */
  background: rgb(109, 169, 228);
  /* Fallback color */
  background: linear-gradient(to right, rgb(109, 169, 228), rgb(246, 186, 111));
  box-shadow: 3px 4px 14px -5px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: 3px 4px 14px -5px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 3px 4px 14px -5px rgba(0, 0, 0, 0.42);
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .wrapper {
    min-height: 750px;
  }
}

.wrapper h2 {
  font-weight: bold;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 24px;
}

.timeline {
  width: 90%;
  padding: 0 35px;
  margin-top: 70px;
}

.timeline ul {
  padding: 0px;
  height: 3px;
  background: black;
  border-radius: 3px;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.timeline ul li:first-child a {
  align-items: start;
}

.timeline ul li:last-child a {
  align-items: end;
}

.timeline ul li {
  list-style: none;
  cursor: pointer;
}

.timeline ul li a {
  text-decoration: none;
  text-align: center;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: black;
  margin-top: 43px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.timeline ul li a::before {
  content: "";
  display: flex;
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid black;
  border-radius: 50%;
  margin-bottom: 20px;
}

.timeline ul li.active a::before {
  background: black;
}

.panel {
  display: flex;
}

.panel .arrows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 30px;
}

.panel .arrows button {
  cursor: pointer;
  background: transparent;
  border: none;
}

.panel .content {
  margin-top: 90px;
  width: 100%;
}

.panel .content .tab {
  display: none;
  padding: 0px 20px;
  color: black;
  height: 300px;
  margin-left: -50px;
  margin-right: -50px;
  text-align: justify;
}

.panel .content .tab.selected {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  animation: 1s ease-out 0s 1 fadeIn;
}

.panel .content .tab h4 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 20;
}

.panel .content .tab p {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
}

.navbar-toggler.collapsed .navbar-toggler-icon {
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .navbar-toggler-icon::before {
  top: 0;
  transform: translateY(-6px);
}

.navbar-toggler.collapsed .navbar-toggler-icon::after {
  bottom: 0;
  transform: translateY(6px);
}

.navbar-toggler {
  position: sticky;
  left: 0;
  z-index: 100;
  background-color: #fff;
  transition: left 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .navbar-toggler.collapsed {
    left: 0;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 70%;
    max-width: 300px;
    padding-top: 60px;
    background-color: #fff;
    transition: left 0.3s ease-in-out;
    z-index: 9999;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .navbar-collapse.show {
    left: 0;
  }

  .navbar-collapse .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .navbar-collapse .sub-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .navbar-collapse .sub-menu-item {
    padding: 10px 20px;
  }
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;
}

.rs-dropdown-toggle {
  color: black;
}


.vertical-tabs {
  display: flex;
  align-items: flex-start;
}

.tab-list {
  display: flex;
  flex-direction: column;
  height: 100%;

}

.colorful-pattern {
  background: white;
  background-size: 200% 200%;
  /* animation: gradientAnimation 10s ease infinite; */
  height: 700px;
  text-align: left;
  width: 12%;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


.tab {
  padding: 10px;
  cursor: pointer;
}


.tab.active {
  position: relative;
  background-color: rgb(173, 228, 219);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid rgb(246, 186, 111);
  /* border-top-right-radius: 50%;
    border-bottom-right-radius: 50%; */
  animation: slideArrow 1s linear alternate;
  margin-right: 45px;
}

.tab.active::before {
  content: "";
  position: absolute;
  top: -11px;
  right: -44px;
  border-top: 33px solid transparent;
  border-bottom: 33px solid transparent;
  border-left: 45px solid rgb(173, 228, 219);
  animation: slideArrow 1s linear alternate;
}

@keyframes slideArrow {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}


.tab-content {
  flex: 1;
  padding: 10px;
  background-color: #fff;
  color: #333;
  overflow-y: auto;
}

/* Styles for mobile view */
.mobile-view {
  position: relative;
}

.mobile-view .menu-icon {
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  z-index: 2;
}

.mobile-view .bar {
  width: 100%;
  height: 3px;
  background-color: #000;
  transition: background-color 0.3s ease-in-out;
}

.mobile-view .menu-icon.open .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.mobile-view .menu-icon.open .bar:nth-child(2) {
  opacity: 0;
}

.mobile-view .menu-icon.open .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.mobile-view .menu-dropdown {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: #fff;
  padding: 10px;
  z-index: 1;
}

.mobile-view .menu-dropdown .tab {
  width: 100%;
}

.mobile-view .menu-dropdown .tab.mobactive {
  background-color: #ccc;
}

.about-dept {
  width: 100%;
  background-color: #f5f5f5;
  /* Background color */
  background-image:
    radial-gradient(circle, #dddddd 10%, transparent 10%),
    radial-gradient(circle, #cccccc 10%, transparent 10%),
    radial-gradient(circle, #bbbbbb 10%, transparent 10%),
    radial-gradient(circle, #aaaaaa 10%, transparent 10%),
    radial-gradient(circle, #999999 10%, transparent 10%),
    radial-gradient(circle, #888888 10%, transparent 10%);
  background-size: 15px 15px;
  /* Adjust the size of the pattern */
}


.aboutdeptcontent {
  max-width: 960px;
  /* Set the maximum content width */
  margin: 0 auto;
  /* Center the content horizontally */
  padding: 40px;
  /* Add some padding to the content */
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .aboutdeptcontent {
    padding: 20px;
    /* Adjust the padding for smaller screens */
  }
}

/*teachers.js component css*/
.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card-container-teacher {
  display: flex;
  flex-wrap: wrap;
}

.card-teacher {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  margin: 20px;
  min-width: auto;
  padding-left: 60px;
  padding-right: 60px;
}

.card:hover {
  transform: translateY(-5px);
}

.card-image {
  position: relative;
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%
}

.card-image img {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
  width: 200px;
  height: 200px;
}

.card-content {
  padding: 20px;
}

.card-info {
  text-align: center;
  margin-bottom: 15px;
}

.card-name {
  font-size: 15px;
  font-weight: bold;
  color: #333;
}

.card-email {
  font-size: 14px;
  color: #666;
}

.card-designation {
  font-size: 14px;
  color: #666;
}

.card-date {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  color: #999;
}

.card-join-badge {
  /* position: absolute;
  top: 10px;
  left: 10px; */
  padding: 6px 10px;
  background-color: rgb(246, 186, 111);
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  margin-left: -69%;
}


@media screen and (max-width: 768px) {
  .card-list {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}


.fontcolor {
  -webkit-text-fill-color: #ECF2FF;
}

/* Footer CSS*/


* {
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

.pg-footer {
  font-family: 'Roboto', sans-serif;
}


.footer {
  background: linear-gradient(to left, rgb(255, 235, 235), rgb(173, 228, 219));
  color: #fff;
}

.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}

.footer-wave-path {
  fill: #fff;
}

.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 450px;
  position: relative;
}

.footer-content-column {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: #fff;
}

.footer-content-column ul li a {
  color: rgb(123, 123, 123);
  text-decoration: none;
}

.footer-logo-link {
  display: inline-block;
}

.footer-menu {
  margin-top: 30px;
}

.footer-menu-name {
  color: rgb(109, 169, 228);
  font-size: 15px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-menu-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}

.footer-menu-list li {
  margin-top: 5px;
}

.footer-call-to-action-description {
  color: rgb(123, 123, 123);
  margin-top: 10px;
  margin-bottom: 20px;
}

.footer-call-to-action-button:hover {
  background-color: #fffff2;
  color: rgb(109, 169, 228);
}

.button:last-of-type {
  margin-right: 0;
}

.footer-call-to-action-button {
  background-color: rgb(109, 169, 228);
  border-radius: 21px;
  color: #fffff2;
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .2s;
  cursor: pointer;
  position: relative;
}

.footer-call-to-action {
  margin-top: 30px;
}

.footer-call-to-action-title {
  color: rgb(109, 169, 228);
  font-size: 14px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-call-to-action-link-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  color: rgb(109, 169, 228);
  text-decoration: none;
}

.footer-call-to-action-link-wrapper a {
  color: rgb(123, 123, 123);
  text-decoration: none;
}





.footer-social-links {
  bottom: 0;
  height: 54px;
  position: absolute;
  right: 0;
  width: 236px;
}

.footer-social-amoeba-svg {
  height: 54px;
  left: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 236px;
}

.footer-social-amoeba-path {
  fill: rgb(109, 169, 228);
}

.footer-social-link.linkedin {
  height: 26px;
  left: 3px;
  top: 11px;
  width: 40px;
}

.footer-social-link {
  display: block;
  padding: 10px;
  position: absolute;
}

.hidden-link-text {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  top: 50%;
}

.footer-social-icon-svg {
  display: block;
}

.footer-social-icon-path {
  fill: #fffff2;
  transition: fill .2s;
}

.footer-social-link.twitter {
  height: 28px;
  left: 62px;
  top: 3px;
  width: 40px;
}

.footer-social-link.youtube {
  height: 24px;
  left: 123px;
  top: 12px;
  width: 40px;
}

.footer-social-link.github {
  height: 34px;
  left: 172px;
  top: 7px;
  width: 40px;
}

.footer-copyright {
  background-color: rgb(109, 169, 228);
  color: #fff;
  padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-text {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.footer-copyright-link {
  color: #fff;
  text-decoration: none;
}







/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px) {

  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width:480px) and (max-width:599px) {

  /* smartphones, Android phones, landscape iPhone */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width:600px) and (max-width: 800px) {

  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width:801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */

}

@media (min-width:1025px) {
  /* big landscape tablets, laptops, and desktops */

}

@media (min-width:1281px) {
  /* hi-res laptops and desktops */

}




@media (min-width: 760px) {
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
  }

  .footer-wave-svg {
    height: 50px;
  }

  .footer-content-column {
    width: 24.99%;
  }
}

@media (min-width: 568px) {
  /* .footer-content-column {
      width: 49.99%;
  } */
}


/*Universal Gallery CSS*/
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.gallery__item {
  position: relative;
  cursor: pointer;
}

.gallery__image {
  width: 100%;
  height: auto;
}

.slideshow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
}

.slideshow.active {
  opacity: 1;
  pointer-events: auto;
}

.slideshow__close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  font-size: 16px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.slideshow__content {
  max-width: 90%;
  max-height: 90%;
  text-align: center;
}

.slideshow__image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.slideshow__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  font-size: 24px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.slideshow__arrow--left {
  left: 10px;
}

.slideshow__arrow--right {
  right: 10px;
}

/* Just for fun */
@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.mr-3 {
  animation: rotate 10s infinite;
  /* Adjust the duration and timing function as needed */
}

/* CSS for NoticePage */
.noticetabs {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
}

.noticetabs button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6DA9E4;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  /* border-radius: 40px; */
  cursor: pointer;
  margin-right: -1px;
  color: #fff;
  transition: transform 0.3s;
}

.noticetabs button.active {
  background-color: #F6BA6F;
  position: relative;
  transform: translateY(-10px);
}

.noticetabs button.active:before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #F6BA6F;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.noticetabs button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.noticetabs button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 0;
}

.filters {
  margin-bottom: 20px;
}

.filters input {
  margin-right: 10px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.notice-tab-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.item {
  background-color: #ADE4DB;
  padding: 20px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.item h3 {
  margin-top: 0;
}

.show-more {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6DA9E4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 550px;
  max-height: 100vh;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .popup-content {
    width: 400px;
    max-height: 100vh;
    overflow-y: auto;
  }
}


.download-button,
.close-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6DA9E4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  color: #fff;
}

.download-button {
  background-color: #4caf50;
  margin-right: 10px;
}

.close-button {
  background-color: #f44336;
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
  .tab-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

/*CSS for Slider */

.slider-image {
  object-fit: cover;
  object-position: center;
  height: 450px;
}

.learn-more-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

/* Academic Calendar CSS */
/* Calendar Container */
.calendar-container {
  max-width: 100%;
  overflow-x: auto;
}

/* Calendar Table */
.calendar-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header */
.calendar-table thead th {
  background-color: rgb(255, 235, 235);
  color: #333;
  padding: 8px;
  text-align: center;
}

/* Table Cells */
.calendar-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

/* Today's Cell */
.calendar-table td.today {
  background-color: rgb(173, 228, 219);
  color: #fff;
}

/* Holiday Cells */
.calendar-table td.holiday {
  background-color: rgb(109, 169, 228);
  color: #fff;
}

/* Other Month Cells */
.calendar-table td.other-month {
  background-color: rgb(246, 186, 111);
}

/* Button Container */
.button-container {
  margin-bottom: 10px;
}

/* Button Styles */
.button-container button {
  margin-right: 10px;
  background-color: rgb(246, 186, 111);
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.button-container button:hover {
  background-color: rgb(173, 228, 219);
}


.fc .fc-scrollgrid-liquid {
  height: 100% !important;
}

/* Program Outcome CSS */

.MuiGrid-grid-md-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  padding-right: 10px;
}

/* Timetable.css */

.timetable-container {
  width: 100%;
  overflow-x: auto;
}

.timetable {
  width: 100%;
  border-collapse: collapse;
}

.timetable th,
.timetable td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ccc;
}

.timetable th {
  background-color: rgb(109, 169, 228);
  /* Modified header background color */
  color: white;
  /* Added text color for better visibility */
}

.timetable tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.timetable tbody td {
  vertical-align: middle;
}

.timetable td p {
  font-size: 15px;
  /* Adjust the font size as desired */
  font-weight: bold;
  /* Make the text bold */
  line-height: 1.2;
  /* Adjust the line height as desired */
}

/* Adjust styles for smaller screens */
@media (max-width: 600px) {
  .timetable-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .timetable th,
  .timetable td {
    font-size: 12px;
    padding: 6px;
  }

  .timetable td p {
    font-size: 10px;
    /* Adjust the font size for smaller screens */
  }
}

.semester-table embed {
  width: 100%;
  height: 1000px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: rgb(109, 169, 228) !important;
}

/* Dummy CSS */

.hierarchy-component {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: flex-start;
}

.stream {
  margin-bottom: 20px;
}

.stream-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.core-title {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 10px;
  position: relative;
}

.core-title::before {
  content: "▶";
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.core-title.expanded::before {
  transform: rotate(0deg);
}

.combinations {
  margin-left: 20px;
}

.combinations-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #f5f5f5;
  margin-top: 5px;
}

.combinations-table th,
.combinations-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.combination-row:hover {
  background-color: #ebebeb;
}

.table-responsive {
  overflow-x: auto;
  width: 80%;
  margin: 0 auto;
  /* Center the table horizontally */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.link-cell a {
  display: block;
  text-align: center;
}

.text-cell {
  text-align: left;
}

.link-cell img {
  display: block;
  margin: 0 auto;
  max-width: 200px;
}

.table thead th {
  background-color: rgb(246, 186, 111);
  color: white;
  font-weight: bold;
}

.table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

@media (max-width: 767px) {
  .table-responsive {
    width: 100%;
    overflow-y: auto;
    /* Enable vertical scrolling on small screens if needed */
    border: 1px solid #dee2e6;
  }

  .table-responsive>.table {
    margin-bottom: 0;
    background-color: transparent;
    white-space: normal;
    /* Allow text wrapping in mobile view */
  }

  .table-responsive>.table>thead>tr>th,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tfoot>tr>td {
    white-space: normal;
    /* Allow text wrapping in mobile view */
  }

  .table-responsive>.table tbody tr:nth-child(even) {
    background-color: #f8f8f8;
    /* Alternating row color in mobile view */
  }
}

/*Magazine Page CSS */
.album-web {
  background: rgb(255, 251, 251);
  text-align: center;
}

.page {
  box-shadow: 0 1.5em 3em -1em rgb(70, 69, 69);
}

.cover {
  background-color: rgb(251, 225, 139);
  box-shadow: 0 1.5em 3em -1em rgb(70, 69, 69);
}

.form-control {
  --padding-x: 0.5em;
}


/* Sahar CSS*/
.sahara-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sahara-text {
  flex-basis: 60%;
}

.sahara-images {
  flex-basis: 35%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.sahara-image {
  width: 200px;
  margin: 10px;
  position: relative;
  perspective: 1000px;
  /* Set the perspective for 3D transforms */
}

.sahara-image img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
  /* Add transition for smooth animation */
}

.sahara-image:hover img {
  transform: rotateY(180deg);
  /* Flip the image on hover */
}

@media (max-width: 768px) {
  .sahara-container {
    flex-direction: column;
  }

  .sahara-text,
  .sahara-images {
    flex-basis: 100%;
  }
}

.activitycard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.activitycard {
  position: relative;
  flex: 1 1 300px;
  min-height: fit-content;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.event-image {
  position: relative;
  /* Change position to relative */
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin: 0 auto;
  /* Center horizontally within the card */
  margin-top: 2%;
}

.activitycard-body {
  padding: 20px;
}

.activitycard-body p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.cipopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.cipopup-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  width: 90%;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
}

.image-item {
  width: 100%;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  grid-gap: 10px;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .event-image {
    margin-top: 8%;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    grid-gap: 10px;
    margin-top: 20px;
  }
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 10px;
}

.Primary {
  background-color: #007bff;
  color: #fff;
}

.Secondary {
  background-color: #6c757d;
  color: #fff;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
}

.description {
  margin-top: 20px;
}

.cellsul {
  list-style-type: none;
  padding-left: 20px;
  text-align: left !important;
}

/* li::before {
  content: '\2713';
  margin-right: 5px;
} */
.headerul::before {
  content: '\2192';
  /* Unicode character for right arrow */
  color: #adadad;
  margin-right: 10px;
  font-weight: 300;
  transform: rotate(90deg);
}

.headerul {
  text-align: left;
}

h2,
h3 {
  margin-top: 20px;
}

.fullbody {
  width: 75%;
  margin: 0 auto;
  /* Add this property */
  text-align: center;
  /* Add this property */
}

.row {
  justify-content: center;
}

.register-button {
  /* Add your custom styling for the Register button */
  animation: flash 1s infinite;
  margin-right: 10px !important;
}

@keyframes flash {
  0% {
    background-color: yellow !important;
    color: black;
  }

  50% {
    background-color: red !important;
    color: white;
  }

  100% {
    background-color: yellow !important;
    color: black;
  }
}

.tox-notifications-container {
  display: none !important;
}

.tox-statusbar__branding {
  display: none !important;
}

/* @media (max-width: 767px) {
  .dashboardtopbar {
    display: none !important;
  }
} */





select.trialbutton,
input[type="number"].trialbutton,
input[type="file"].trialbutton {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button.trialbutton {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button.trialbutton:hover {
  background-color: #0056b3;
}

/* Optional: You can customize the toast notification appearance */
.Toastify__toast--success {
  background-color: #28a745;
}

.Toastify__toast--error {
  background-color: #dc3545;
}

.container.loading {
  position: relative;
  pointer-events: none;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Add line styles */
.select-line {
  border-bottom-width: 2px;
  font-weight: bold;
}

.unique-card-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.unique-card-container {
  width: 400px;
  height: 600px;
  /* Adjust the height to accommodate the pie chart */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  perspective: 1000px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unique-card-container:hover .unique-card-content {
  transform: rotateY(180deg);
}

.unique-card-content {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.unique-card-front,
.unique-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.unique-card-front {
  transform: rotateY(0deg);
}

.unique-card-back {
  transform: rotateY(180deg);
}

.unique-card-image {
  width: 50%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.unique-card-text {
  margin-bottom: 10px;
}

.unique-card-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.unique-card-button:hover {
  background-color: #0056b3;
}

/* Additional styles for the pie chart on the backside */
.unique-card-back .unique-card-pie-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.unique-card-pie-chart canvas {
  max-width: 100%;
  max-height: 100%;
}

.modern-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.custom-button:hover {
  background-color: #0056b3;
}

.button-container {
  margin-top: 10px;
  text-align: center;
}
/* Add this CSS code to your stylesheets or style section */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.unique-heading-classname {
  animation: fadeIn 1s ease-in;
  margin-top: -5%;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: -5%;
}

.coming-soon-image {
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-item span {
  font-size: 32px;
  font-weight: bold;
}

.countdown-item span,
.countdown-item label {
  margin-bottom: 5px;
}
