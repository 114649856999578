.image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
  
  .circular-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  
  .text-container {
    text-align: center;
  }
  
  .text-container span {
    display: block;
    margin-bottom: 5px;
  }
  
  .text-container a {
    display: block;
  }
  