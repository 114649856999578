.split-section {
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  background-repeat: repeat;
  min-height: 200px;
  height: auto;
  align-items: center;
}
.divgap {
  background-color:rgb(109, 169, 228);
}

.split-section-second {
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  background-repeat: repeat;
  min-height: 200px;
  height: auto;
  align-items: center;
}

.notices-counter-section {
  display: flex;
  flex-direction: row;
  min-height: 200px;
  height: auto;
  align-items: center;
}

.left-content {
  flex: 3;
  /* Styles for the left content */
}

.notices-section {
  flex: 50%;
}

.right-content {
  flex: 1;
  /* Styles for the right content */
}

.counter-component {
  flex: 50%;
}

/* Media query for mobile view */
@media (max-width: 767px) {
  .split-section {
    flex-direction: column;
  }

  .split-section-second {
    flex-direction: column;
  }

  .notices-counter-section {
    flex-direction: column;
  }

  .right-content,
  .counter-component,
  .notices-section {
    flex: none;
    /* Reset flex value for the right content, counter component, and notices section */
  }

  .left-content {
    width: 100%;
    flex: none;
    /* Reset flex value and set width to 100% for full width */
  }
}
